function Footer() {
  return (
    <div className="my-12 text-center">
      {/* docs */}
      <a
        href="https://learn.charm.fi/charm-finance/alpha-vaults/"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block px-6 py-2 text-gray-500 hover:text-gray-700"
      >
        Docs
      </a>

      {/* discord */}
      <a
        href="https://discord.gg/6BY3Fq2"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block px-6 py-2 text-gray-500 hover:text-gray-700"
      >
        Discord
      </a>

      {/* telegram */}
      <a
        href="https://t.me/charmfinance"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block px-6 py-2 text-gray-500 hover:text-gray-700"
      >
        Telegram
      </a>

      {/* twitter */}
      <a
        href="https://twitter.com/charmfinance"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block px-6 py-2 text-gray-500 hover:text-gray-700"
      >
        Twitter
      </a>

      {/* github */}
      <a
        href="https://github.com/charmfinance/alpha-vaults-contracts"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block px-6 py-2 text-gray-500 hover:text-gray-700"
      >
        Github
      </a>
    </div>
  )
}

export default Footer
