import { Link } from 'react-router-dom'
import { Decimal } from 'decimal.js'
import { Vault } from 'types/vault'
import { display } from 'utils/math'
import { CHAIN_ID, VAULTS } from '../constants'

interface ListVaultsProps {
  vaults?: Vault[]
}

function ListVaults({ vaults }: ListVaultsProps) {
  if (!vaults) {
    return <div className="mt-24 text-2xl text-gray-400 text-center tracking-wide">Loading...</div>
  }

  return (
    <div className="mx-auto max-w-6xl flex flex-col">
      <h1 className="mt-10 mx-auto px-8 text-3xl md:text-4xl text-center text-gray-900 font-bold">
        <span className="text-indigo-600">Liquidity Vaults</span> for long-term investors
      </h1>

      <div className="mt-4 mx-auto px-8 text-lg md:text-xl text-center text-gray-500 tracking-wide">
        The easiest way to provide liquidity on Uniswap V3
      </div>

      <div className="mt-5 mx-auto text-md md:text-md text-center text-gray-700 font-medium underline">
        <a
          href="https://medium.com/charmfinance/introducing-alpha-passive-95d0f76436a4"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
      </div>

      <div className="mt-12 lg:mt-14 mx-auto px-12 sm:px-36 md:px-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {vaults.map(({ maxTotalSupply, price, total0, total1, totalSupply }, i) => {
          const info = VAULTS[CHAIN_ID][i]
          const tvl1 = total0.mul(price).add(total1)
          const tvl = info.invertPair ? tvl1.div(price) : tvl1
          const isOpen = maxTotalSupply.gt(0)

          let utilization = totalSupply.mul(100).div(maxTotalSupply)
          utilization = utilization.gte(new Decimal('99.89')) ? new Decimal(100) : utilization

          return (
            <Link to={'/vault/' + info.vaultAddress} key={info.vaultAddress}>
              <div className="max-w-xs bg-white rounded-2xl p-12 pt-4 shadow-sm hover:shadow-md border border-transparent hover:border-gray-200">
                <div className="mt-4">
                  {info.withdrawOnly ? (
                    <div className="inline-block text-xs bg-red-50 px-3 py-1 text-gray-600 font-medium tracking-wide rounded-full">
                      Withdraw only
                    </div>
                  ) : !isOpen ? (
                    <div className="inline-block text-xs bg-red-50 px-3 py-1 text-gray-600 font-medium tracking-wide rounded-full">
                      Not open yet
                    </div>
                  ) : (
                    <div className="inline-block text-xs bg-green-50 px-3 py-1 text-gray-600 font-medium tracking-wide rounded-full">
                      Active
                    </div>
                  )}
                  <div className="ml-2 inline-block text-xs bg-indigo-50 px-3 py-1 text-gray-600 font-medium tracking-wide rounded-full">
                    {info.version}
                  </div>
                </div>

                <h2 className="mt-3 text-gray-900 text-2xl font-semibold tracking-wide">
                  {info.symbolA} / {info.symbolB}
                </h2>

                <div className="mt-3 text-gray-600 text-sm tracking-wide">{info.description}</div>

                <div className="mt-10">
                  <div className="mt-6 text-xs text-gray-400 font-semibold tracking-wide">TVL</div>
                  <div className="text-md text-indigo-600 font-semibold tracking-wider">${display(tvl, 4)}</div>

                  <div className="mt-6 text-xs text-gray-400 font-semibold tracking-wide">Capacity used</div>
                  <div className="text-md text-indigo-600 font-semibold tracking-wider">
                    {isOpen ? display(utilization, 3) + '%' : '-'}
                  </div>
                </div>
              </div>
            </Link>
          )
        })}

        <a href="https://eth-usdc.alpha.charm.fi/">
          <div className="max-w-xs bg-white rounded-2xl p-12 pt-4 shadow-sm hover:shadow-md border border-transparent hover:border-gray-200">
            <div className="mt-4">
              <div className="inline-block text-xs bg-red-50 px-3 py-1 text-gray-600 font-medium tracking-wide rounded-full">
                Withdraw only
              </div>
              <div className="ml-2 inline-block text-xs bg-indigo-50 px-3 py-1 text-gray-600 font-medium tracking-wide rounded-full">
                V0
              </div>
            </div>

            <h2 className="mt-3 text-gray-900 text-2xl font-semibold tracking-wide">ETH / USDC</h2>

            <div className="mt-3 text-gray-600 text-sm tracking-wide">
              Manages your liquidity using a passive rebalancing strategy
            </div>

            <div className="mt-10">
              <div className="mt-6 text-xs text-gray-400 font-semibold tracking-wide">TVL</div>
              <div className="text-md text-indigo-600 font-semibold tracking-wider">-</div>

              <div className="mt-6 text-xs text-gray-400 font-semibold tracking-wide">Capacity used</div>
              <div className="text-md text-indigo-600 font-semibold tracking-wider">100%</div>
            </div>
          </div>
        </a>

        <div>
          <div className="max-w-xs rounded-2xl p-12 border-4 border-gray-200 border-dotted">
            <h2 className="text-gray-400 text-lg font-medium tracking-wider text-center">
              More vaults
              <br />
              coming soon!
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListVaults
