import { useEffect, useState } from 'react'
import { useWallet } from 'use-wallet'
import Web3 from 'web3'

export const useBlock = () => {
  const [block, setBlock] = useState(0)
  const { ethereum } = useWallet()

  useEffect(() => {
    if (!ethereum) {
      return
    }

    // @ts-ignore
    const e = ethereum
    const web3 = new Web3(e as any)

    const interval = setInterval(() => {
      web3.eth.getBlockNumber((error, _block) => {
        if (block !== _block) {
          setBlock(_block)
        }
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [ethereum, block])

  return block
}

export default useBlock
