import { Decimal } from 'decimal.js'
import { useState } from 'react'
import { Vault } from 'types/vault'
import { useWallet } from 'use-wallet'
import { display } from 'utils/math'
import { sendApprove, sendDeposit } from 'utils/methods'
import Web3 from 'web3'
import { ZERO_ADDRESS } from '../../constants'
import { GrayButton, IndigoButton } from '../Buttons'
import Loading from '../Loading'
import NumericalInput from '../NumericalInput'

type DepositPanelProps = {
  setIsDeposit: (b: boolean) => void
} & Vault

function DepositPanel({
  accountAddress,
  setIsDeposit,
  vaultAddress,
  token0Address,
  token1Address,
  symbol0,
  symbol1,
  decimals0,
  decimals1,
  allowance0,
  allowance1,
  balance0,
  balance1,
  total0,
  total1,
  totalSupply,
  maxTotalSupply,
}: DepositPanelProps) {
  const { account, connect, ethereum, status } = useWallet()
  const [value0, setValue0] = useState<Decimal.Value>('')
  const [value1, setValue1] = useState<Decimal.Value>('')

  const [isPending, setIsPending] = useState(false)
  const [isPending0, setIsPending0] = useState(false)
  const [isPending1, setIsPending1] = useState(false)

  const amount0 = new Decimal(value0 || 0)
  const amount1 = new Decimal(value1 || 0)

  // round up total amounts in case they're too small, otherwise shares
  // sent to deposit might be too low
  const eps0 = Decimal.pow(10, -decimals0).mul(2)
  const eps1 = Decimal.pow(10, -decimals1).mul(2)
  const shares0 = totalSupply.mul(amount0).div(total0.add(eps0))
  const shares1 = totalSupply.mul(amount1).div(total1.add(eps1))
  const shares = Decimal.min(shares0, shares1)

  // console.log('total', total0.toFixed(), total1.toFixed());
  // console.log('eps', eps0.toFixed(), eps1.toFixed());
  // console.log('amount', amount0.toFixed(), amount1.toFixed());
  // console.log('supply shares', totalSupply.toFixed(), shares.toFixed());
  // console.log('shares', amount0.div(total0.add(eps0)).toFixed(), amount1.div(total1.add(eps1)).toFixed());
  // console.log('shares', shares.toFixed());
  // console.log('maxTotalSupply', maxTotalSupply.toFixed());

  const onChangeValue0 = (value: string) => {
    setValue0(value)
    if (total0.gt(0)) {
      setValue1(
        Decimal.mul(value || 0, total1)
          .div(total0)
          .toSignificantDigits(8, Decimal.ROUND_DOWN)
      )
    }
  }

  const onChangeValue1 = (value: string) => {
    setValue1(value)
    if (total1.gt(0)) {
      setValue0(
        Decimal.mul(value || 0, total0)
          .div(total1)
          .toSignificantDigits(8, Decimal.ROUND_DOWN)
      )
    }
  }

  const onSetMax0 = () => {
    onChangeValue0(balance0.toString())
  }

  const onSetMax1 = () => {
    onChangeValue1(balance1.toString())
  }

  const onApprove0 = () => {
    if (!account) {
      return
    }
    // @ts-ignore
    const e = ethereum
    const web3 = new Web3(e as any)
    sendApprove({
      web3,
      account,
      vaultAddress,
      tokenAddress: token0Address,
      setIsPending: setIsPending0,
    })
  }

  const onApprove1 = () => {
    if (!account) {
      return
    }
    // @ts-ignore
    const e = ethereum
    const web3 = new Web3(e as any)
    sendApprove({
      web3,
      account,
      vaultAddress,
      tokenAddress: token1Address,
      setIsPending: setIsPending1,
    })
  }

  const onDeposit = () => {
    if (!account) {
      return
    }
    // @ts-ignore
    const e = ethereum
    const web3 = new Web3(e as any)
    const amount0Min = Decimal.mul(value0 || 0, 90).div(100)
    const amount1Min = Decimal.mul(value1 || 0, 90).div(100)
    sendDeposit({
      web3,
      account,
      vaultAddress,
      amount0Desired: value0,
      amount1Desired: value1,
      amount0Min,
      amount1Min,
      decimals0,
      decimals1,
      setIsPending,
    })
  }

  const emptyAmount = amount0.eq(0) && amount1.eq(0)
  const insufficientAllowance0 = allowance0.eq(0) || amount0.gt(allowance0)
  const insufficientAllowance1 = allowance1.eq(0) || amount1.gt(allowance1)
  const insufficientBalance0 = amount0.gt(balance0)
  const insufficientBalance1 = amount1.gt(balance1)
  const isOpen = maxTotalSupply.gt(0)
  const capHit = totalSupply.add(shares).gt(maxTotalSupply.mul(999).div(1000))
  const disabled0 = total0.eq(0) && total1.gt(0)
  const disabled1 = total0.gt(0) && total1.eq(0)

  return (
    <div className="bg-white rounded-2xl shadow-sm">
      <div className="grid grid-cols-2 text-center">
        <button className="p-4 text-gray-900 text-sm font-semibold cursor-auto focus:outline-none">Deposit</button>
        <button
          className="p-4 text-gray-500 text-sm font-semibold bg-pink-100 rounded-tr-2xl focus:outline-none"
          onClick={() => setIsDeposit(false)}
        >
          Withdraw
        </button>
      </div>

      <div className="px-6 md:px-10 py-8 md:py-12">
        <div className="text-sm text-gray-400 tracking-wide">
          Note that deposits are in the same ratio as the vault's current holdings and are therefore not necessarily in
          a 1:1 ratio.
        </div>

        {disabled0 || (
          <div className="mt-12 px-2">
            <div className="text-gray-400 text-sm text-right">
              Balance: {display(balance0, 4)}
              <button className="ml-1 text-indigo-400" onClick={onSetMax0}>
                (Max)
              </button>
            </div>

            <div className="flex flex-row items-center mt-1">
              <div className="w-24 text-gray-900 font-medium text-lg tracking-wider">{symbol0}</div>
              <NumericalInput
                value={value0.toString()}
                onChangeValue={onChangeValue0}
                className="w-48 p-3 text-gray-700 font-medium text-xl text-right tracking-wider border border-pink-100 bg-pink-50 rounded-md"
              />
            </div>
          </div>
        )}

        {disabled1 || (
          <div className="mt-12 px-2">
            <div className="text-gray-400 text-sm text-right">
              Balance: {display(balance1, 4)}
              <button className="ml-1 text-indigo-400" onClick={onSetMax1}>
                (Max)
              </button>
            </div>

            <div className="flex flex-row items-center mt-1">
              <div className="w-24 text-gray-900 font-medium text-lg tracking-wider">{symbol1}</div>
              <NumericalInput
                value={value1.toString()}
                onChangeValue={onChangeValue1}
                className="w-48 p-3 text-gray-700 font-medium text-xl text-right tracking-wider border border-pink-100 bg-pink-50 rounded-md"
              />
            </div>
          </div>
        )}

        <div className="mt-16">
          {status === 'disconnected' ? (
            <IndigoButton onClick={() => connect('injected')}>Connect wallet</IndigoButton>
          ) : status !== 'connected' || accountAddress === ZERO_ADDRESS ? (
            <GrayButton>Connecting...</GrayButton>
          ) : !isOpen ? (
            <GrayButton>Not open yet</GrayButton>
          ) : capHit ? (
            <GrayButton>Deposit cap hit</GrayButton>
          ) : insufficientAllowance0 && insufficientAllowance1 ? (
            <div className="grid grid-cols-2 gap-3">
              {isPending0 ? (
                <GrayButton>
                  Pending <Loading />
                </GrayButton>
              ) : (
                <IndigoButton onClick={onApprove0}>Approve {symbol0}</IndigoButton>
              )}
              {isPending1 ? (
                <GrayButton>
                  Pending <Loading />
                </GrayButton>
              ) : (
                <IndigoButton onClick={onApprove1}>Approve {symbol1}</IndigoButton>
              )}
            </div>
          ) : isPending || isPending0 || isPending1 ? (
            <GrayButton>
              Pending <Loading />
            </GrayButton>
          ) : insufficientAllowance0 ? (
            <IndigoButton onClick={onApprove0}>Approve {symbol0}</IndigoButton>
          ) : insufficientAllowance1 ? (
            <IndigoButton onClick={onApprove1}>Approve {symbol1}</IndigoButton>
          ) : emptyAmount ? (
            <GrayButton>Deposit</GrayButton>
          ) : insufficientBalance0 ? (
            <GrayButton>Insufficient {symbol0}</GrayButton>
          ) : insufficientBalance1 ? (
            <GrayButton>Insufficient {symbol1}</GrayButton>
          ) : (
            <IndigoButton onClick={onDeposit}>Deposit</IndigoButton>
          )}
        </div>
      </div>
    </div>
  )
}

export default DepositPanel
