import { Vault } from 'types/vault'
import { display } from 'utils/math'
import { Decimal } from 'decimal.js'

type PositionPanelProps = Vault & { USDValue: Decimal }

function PositionPanel({ balance, symbol0, symbol1, total0, total1, totalSupply, USDValue }: PositionPanelProps) {
  const amount0 = balance.mul(total0).div(totalSupply)
  const amount1 = balance.mul(total1).div(totalSupply)

  return (
    <div className="bg-white rounded-2xl shadow-sm px-6 md:px-12 lg:px-20 pt-6 pb-10">
      <div className="text-center text-gray-900 text-sm font-semibold">Your positions</div>

      <div className="mt-8 flex flex-row items-center">
        <div className="flex-grow text-gray-900 font-medium text-sm tracking-wider">{symbol0}</div>
        <div className="text-green-400 font-medium text-lg text-right tracking-wider">{display(amount0, 4)}</div>
      </div>

      <div className="mt-2 flex flex-row items-center">
        <div className="flex-grow text-gray-900 font-medium text-sm tracking-wider">{symbol1}</div>
        <div className="text-green-400 font-medium text-lg text-right tracking-wider">{display(amount1, 4)}</div>
      </div>

      <div className="mt-2 flex flex-row items-center">
        <div className="flex-grow text-gray-900 font-medium text-sm tracking-wider">Value</div>
        <div className="text-green-400 font-medium text-lg text-right tracking-wider">${display(USDValue, 2)}</div>
      </div>
    </div>
  )
}

export default PositionPanel
