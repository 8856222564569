import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { IndigoButton } from './Buttons'

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    padding: 0,
    border: 0,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
}

function NewUserModal() {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const seen = localStorage.getItem('alpha.newUserModalSeen')
    if (!seen) {
      setIsOpen(true)
    }
  }, [])

  const onAgree = () => {
    setIsOpen(false)
    localStorage.setItem('alpha.newUserModalSeen', '1')
  }

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <div className="max-w-lg p-14 pt-12">
        <h1 className="text-center text-2xl text-gray-900 font-semibold">Alpha Vaults</h1>

        <p className="mt-8 leading-relaxed text-md text-gray-500 tracking-wide">
          Alpha Vaults is an experimental protocol. It is not regulated, nor is it subjected to the laws and regulations
          applicable to firms, individuals, partnerships, or solo practitioners. You can lose up to 100% of your funds,
          with no recourse to compensation. Please ensure you do your own research and understand the risks before
          continuing.
        </p>

        <div className="mt-12">
          <IndigoButton onClick={onAgree}>I understand and agree</IndigoButton>
        </div>
      </div>
    </Modal>
  )
}

export default NewUserModal
