import { PropsWithChildren } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { UseWalletProvider } from 'use-wallet'
import Footer from './components/Footer'
import Header from './components/Header'
import ListVaults from './components/ListVaults'
import NewUserModal from './components/NewUserModal'
import ToastContainer from './components/ToastContainer'
import Vault from './components/Vault'
import { CHAIN_ID } from './constants'
import useAllVaults from './hooks/useAllVaults'

function Main() {
  const vaults = useAllVaults()
  if (!vaults) {
    return null
  }
  return (
    <Switch>
      <Route path="/" exact>
        <Layout>
          <ListVaults vaults={vaults} />
        </Layout>
      </Route>
      <Route path="/vault/:address">
        <Layout>
          <Vault />
        </Layout>
      </Route>
    </Switch>
  )
}

function Layout<P>({ children }: PropsWithChildren<P>) {
  return (
    <>
      <ToastContainer />
      <NewUserModal />
      <div className="w-screen h-full min-h-screen flex flex-col bg-pink-50 text-gray-700">
        <Header />
        <div className="flex-grow mt-10">{children}</div>
        <Footer />
      </div>
    </>
  )
}

function App() {
  const connectors = {
    walletconnect: { rpcUrl: 'https://mainnet.eth.aragon.network/' },
  }

  return (
    <UseWalletProvider chainId={CHAIN_ID} connectors={connectors}>
      <Router>
        <Main />
      </Router>
    </UseWalletProvider>
  )
}

export default App
