import { PropsWithChildren } from 'react'

type ButtonProps = PropsWithChildren<{
  onClick?: () => any
}>

export function GrayButton({ children }: ButtonProps) {
  return (
    <button
      type="button"
      disabled
      className="w-full inline-flex items-center justify-center h-14 text-sm rounded-sm text-white bg-gray-400 font-medium tracking-wide focus:outline-none cursor-default"
    >
      {children}
    </button>
  )
}

export function IndigoButton({ children, onClick }: ButtonProps) {
  return (
    <button
      type="button"
      className="w-full inline-flex items-center justify-center h-14 text-sm rounded-sm text-white bg-indigo-500 font-medium tracking-wide hover:bg-indigo-600 focus:outline-none"
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export function GraySmallButton({ children }: ButtonProps) {
  return (
    <button
      type="button"
      disabled
      className="inline-flex items-center justify-center px-4 h-10 text-xs rounded-sm text-white bg-gray-400 font-medium tracking-wide focus:outline-none cursor-default"
    >
      {children}
    </button>
  )
}

export function IndigoSmallButton({ children, onClick }: ButtonProps) {
  return (
    <button
      type="button"
      className="inline-flex items-center justify-center px-4 h-10 text-xs rounded-sm text-white bg-indigo-500 font-medium tracking-wide hover:bg-indigo-600 focus:outline-none"
      onClick={onClick}
    >
      {children}
    </button>
  )
}
