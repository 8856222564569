import { useCallback, useEffect, useState } from 'react'
import { Vault } from 'types/vault'
import { useWallet } from 'use-wallet'
import fetchVault from 'utils/fetchVault'
import { CHAIN_ID, VAULTS } from '../constants'

export const useAllVaults = (): Vault[] | null => {
  const [result, setResult] = useState<Vault[] | null>(null)
  const { account } = useWallet()

  const fetch = useCallback(async () => {
    const addresses = VAULTS[CHAIN_ID].map((info) => info.vaultAddress)
    const responses = await Promise.all(addresses.map((address) => fetchVault(address, account || '')))
    setResult(responses)
  }, [account])

  useEffect(() => {
    fetch()
  }, [account, fetch])

  return result
}

export default useAllVaults
