import { useEffect, useState } from 'react'

function Loading() {
  const [count, setCount] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((count + 1) % 4)
    }, 500)
    return () => clearInterval(interval)
  }, [count])

  const dots = '.'.repeat(count)

  return <span className="inline-block w-2 ml-0.5 text-left tracking-wider">{dots}</span>
}

export default Loading
