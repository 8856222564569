import { Decimal } from 'decimal.js'
import { ethers, utils } from 'ethers'
import { Contract, Provider } from 'ethers-multicall'
import { Vault } from 'types/vault'
import { CHAIN_ID, ZERO_ADDRESS } from '../constants'
import AlphaVault from '../constants/abis/AlphaVault.json'
import ERC20 from '../constants/abis/ERC20.json'
import IUniswapV3Pool from '../constants/abis/IUniswapV3Pool.json'
import { tickToPrice, toDecimal } from './math'

const computePositionKey = (owner: string, tickLower: number, tickUpper: number) => {
  return utils.solidityKeccak256(['address', 'int24', 'int24'], [owner, tickLower, tickUpper])
}

export const fetchVault = async (vaultAddress: string, account: string | null): Promise<Vault> => {
  const network = ethers.providers.getNetwork(CHAIN_ID)
  const provider = ethers.getDefaultProvider(network)
  const multicall = new Provider(provider, CHAIN_ID)

  if (!account) {
    account = ZERO_ADDRESS
  }

  const vault = new Contract(vaultAddress, AlphaVault)
  const [
    poolAddress,
    strategyAddress,
    token0Address,
    token1Address,
    maxTotalSupply,
    baseLower,
    baseUpper,
    limitLower,
    limitUpper,
    totalSupply,
    balance,
    totals,
  ] = await multicall.all([
    vault.pool(),
    vault.strategy(),
    vault.token0(),
    vault.token1(),
    vault.maxTotalSupply(),
    vault.baseLower(),
    vault.baseUpper(),
    vault.limitLower(),
    vault.limitUpper(),
    vault.totalSupply(),
    vault.balanceOf(account),
    vault.getTotalAmounts(),
  ])

  const ERC20Patched = ERC20.filter((x) => x.type !== 'fallback')
  const pool = new Contract(poolAddress, IUniswapV3Pool)
  const token0 = new Contract(token0Address, ERC20Patched)
  const token1 = new Contract(token1Address, ERC20Patched)

  const baseKey = computePositionKey(vaultAddress, baseLower, baseUpper)
  const limitKey = computePositionKey(vaultAddress, limitLower, limitUpper)

  const [slot, , , symbol0, symbol1, decimals0, decimals1, allowance0, allowance1, balance0, balance1] =
    await multicall.all([
      pool.slot0(),
      pool.positions(baseKey),
      pool.positions(limitKey),
      token0.symbol(),
      token1.symbol(),
      token0.decimals(),
      token1.decimals(),
      token0.allowance(account, vaultAddress),
      token1.allowance(account, vaultAddress),
      token0.balanceOf(account),
      token1.balanceOf(account),
    ])

  const d0 = parseInt(decimals0)
  const d1 = parseInt(decimals1)

  const _toDecimal = (x: Decimal.Value, dp: number): Decimal => toDecimal(x.toString(), dp)
  const parseTick = (x: Decimal.Value): Decimal => toDecimal(tickToPrice(x.toString()), d1 - d0)

  const anon = account === ZERO_ADDRESS
  const zero = new Decimal(0)

  return {
    vaultAddress: vaultAddress,
    accountAddress: account,
    poolAddress: poolAddress,
    strategyAddress: strategyAddress,
    token0Address: token0Address,
    token1Address: token1Address,
    maxTotalSupply: _toDecimal(maxTotalSupply, 18),
    baseLower: parseTick(baseLower).toNumber(),
    baseUpper: parseTick(baseUpper).toNumber(),
    limitLower: parseTick(limitLower).toNumber(),
    limitUpper: parseTick(limitUpper).toNumber(),
    totalSupply: _toDecimal(totalSupply, 18),
    balance: anon ? zero : _toDecimal(balance, 18),
    total0: _toDecimal(totals.total0, d0),
    total1: _toDecimal(totals.total1, d1),
    price: parseTick(slot.tick),
    symbol0: symbol0,
    symbol1: symbol1,
    decimals0: d0,
    decimals1: d1,
    allowance0: anon ? zero : _toDecimal(allowance0, d0),
    allowance1: anon ? zero : _toDecimal(allowance1, d1),
    balance0: anon ? zero : _toDecimal(balance0, d0),
    balance1: anon ? zero : _toDecimal(balance1, d1),
  }
}

export default fetchVault
