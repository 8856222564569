interface PopoutIconProps {
  href: string
}

function PopoutIcon({ href }: PopoutIconProps) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <svg className="inline-flex w-4 h-4 ml-1 mb-1 fill-current text-gray-500" viewBox="0 0 24 24" aria-hidden="true">
        <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
      </svg>
    </a>
  )
}

export default PopoutIcon
