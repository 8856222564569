import Web3 from 'web3'
import ERC20 from '../constants/abis/ERC20.json'
import PassiveRebalanceVault from '../constants/abis/PassiveRebalanceVault.json'
import WETH9 from '../constants/abis/WETH9.json'
import { Decimal } from 'decimal.js'
import handleTransaction from './handleTransaction'
import { toString, UINT256_MAX } from './math'

interface sendApproveArgs {
  web3: Web3
  account: string
  vaultAddress: string
  tokenAddress: string
  setIsPending: (b: boolean) => void
}
export const sendApprove = async ({ web3, account, vaultAddress, tokenAddress, setIsPending }: sendApproveArgs) => {
  console.log('Approve')
  console.log('Vault:          ' + vaultAddress)
  console.log('Token:          ' + tokenAddress)

  const token = new web3.eth.Contract(ERC20 as any, tokenAddress)
  const tx = token.methods.approve(vaultAddress, UINT256_MAX.toFixed())
  const params = { from: account, gas: 0 }

  // estimate gas and add 10%
  const gas = await tx.estimateGas(params)
  params.gas = Math.ceil((gas * 11) / 10)

  return await tx.send(params, async (error: string, txHash: string) => {
    setIsPending(true)
    await handleTransaction(web3, error, txHash)
    setIsPending(false)
  })
}

interface sendDepositArgs {
  web3: Web3
  account: string
  vaultAddress: string
  amount0Desired: Decimal.Value
  amount1Desired: Decimal.Value
  amount0Min: Decimal.Value
  amount1Min: Decimal.Value
  decimals0: Decimal.Value
  decimals1: Decimal.Value
  setIsPending: (b: boolean) => void
}
export const sendDeposit = async ({
  web3,
  account,
  vaultAddress,
  amount0Desired,
  amount1Desired,
  amount0Min,
  amount1Min,
  decimals0,
  decimals1,
  setIsPending,
}: sendDepositArgs) => {
  amount0Desired = toString(amount0Desired, decimals0)
  amount1Desired = toString(amount1Desired, decimals1)
  amount0Min = toString(amount0Min, decimals0)
  amount1Min = toString(amount1Min, decimals1)

  console.log('Deposit')
  console.log('Vault:           ' + vaultAddress)
  console.log('amount0Desired:  ' + amount0Desired)
  console.log('amount1Desired:  ' + amount1Desired)
  console.log('amount0Min:      ' + amount0Min)
  console.log('amount1Min:      ' + amount1Min)

  const vault = new web3.eth.Contract(PassiveRebalanceVault as any, vaultAddress)
  const tx = vault.methods.deposit(amount0Desired, amount1Desired, amount0Min, amount1Min, account)
  const params = { from: account, gas: 0 }

  // estimate gas and add 10%
  const gas = await tx.estimateGas(params)
  params.gas = Math.ceil((gas * 11) / 10)

  return await tx.send(params, async (error: string, txHash: string) => {
    setIsPending(true)
    await handleTransaction(web3, error, txHash)
    setIsPending(false)
  })
}

interface sendWithdrawArgs {
  web3: Web3
  account: string
  vaultAddress: string
  value: string
  amount0Min: Decimal.Value
  amount1Min: Decimal.Value
  decimals0: Decimal.Value
  decimals1: Decimal.Value
  setIsPending: (b: boolean) => void
}
export const sendWithdraw = async ({
  web3,
  account,
  vaultAddress,
  value,
  amount0Min,
  amount1Min,
  decimals0,
  decimals1,
  setIsPending,
}: sendWithdrawArgs) => {
  const valueString = toString(value, 18)
  const amount0MinString = toString(amount0Min, decimals0)
  const amount1MinString = toString(amount1Min, decimals1)

  console.log('Withdraw')
  console.log('Vault:          ' + vaultAddress)
  console.log('value:         ' + valueString)
  console.log('amount0Min:     ' + amount0MinString)
  console.log('amount1Min:     ' + amount1MinString)

  const vault = new web3.eth.Contract(PassiveRebalanceVault as any, vaultAddress)
  const tx = vault.methods.withdraw(valueString, amount0MinString, amount1MinString, account)
  const params = { from: account, gas: 0 }

  // estimate gas and add 10%
  const gas = await tx.estimateGas(params)
  params.gas = Math.ceil((gas * 11) / 10)

  return await tx.send(params, async (error: string, txHash: string) => {
    setIsPending(true)
    await handleTransaction(web3, error, txHash)
    setIsPending(false)
  })
}

interface sendWrapEthArgs {
  web3: Web3
  account: string
  weth9Address: string
  value: string
  setIsPending: (b: boolean) => void
}
export const sendWrapEth = async ({ web3, account, weth9Address, value, setIsPending }: sendWrapEthArgs) => {
  value = toString(value, 18)

  console.log('Wrap ETH')
  console.log('WETH9 address:  ' + weth9Address)
  console.log('value:          ' + value)

  const vault = new web3.eth.Contract(WETH9.abi as any, weth9Address)
  const tx = vault.methods.deposit()
  const params = { from: account, value: value, gas: 0 }

  // estimate gas and add 10%
  const gas = await tx.estimateGas(params)
  params.gas = Math.ceil((gas * 11) / 10)

  return await tx.send(params, async (error: string, txHash: string) => {
    setIsPending(true)
    await handleTransaction(web3, error, txHash)
    setIsPending(false)
  })
}
