import { toast } from 'react-toastify'
import Web3 from 'web3'
import PopoutIcon from 'components/PopoutIcon'
import { CHAIN_ID, ETHERSCAN_LINK } from '../constants'

const handleTransaction = async (web3: Web3, error: string, txHash: string) => {
  console.log('Transaction hash: ', txHash)

  // cannot send
  if (error) {
    console.log('Transaction error: ', error)
    if (txHash) {
      toast(
        <>
          Couldn't send transaction
          <PopoutIcon href={ETHERSCAN_LINK[CHAIN_ID] + 'tx/' + txHash} />
        </>
      )
    }
    return false
  }

  // wait for confirmation on blockchain
  const status = await waitTransaction(web3, txHash)

  // failed
  if (!status) {
    toast(
      <>
        Transaction failed!
        <PopoutIcon href={ETHERSCAN_LINK[CHAIN_ID] + 'tx/' + txHash} />
      </>
    )
    return false
  }

  // successful
  toast(
    <>
      Transaction successful!
      <PopoutIcon href={ETHERSCAN_LINK[CHAIN_ID] + 'tx/' + txHash} />
    </>
  )
  return true
}

const waitTransaction = async (web3: Web3, txHash: string) => {
  while (true) {
    const r = await web3.eth.getTransactionReceipt(txHash)
    if (r !== null) {
      return r.status
    }
    await sleep(1000)
  }
}

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export default handleTransaction
