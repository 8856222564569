import { VaultInfo } from 'types/vault'

export enum SupportedChainId {
  MAINNET = 1,
  RINKEBY = 4,
}

export const CHAIN_ID = SupportedChainId.MAINNET
// export const CHAIN_ID = SupportedChainId.RINKEBY

export const ETHERSCAN_LINK: Record<SupportedChainId, string> = {
  [SupportedChainId.MAINNET]: 'https://etherscan.io/',
  [SupportedChainId.RINKEBY]: 'https://rinkeby.etherscan.io/',
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// TODO: infer invertPair from whether one token is ETH or a stable
export const VAULTS: Record<SupportedChainId, VaultInfo[]> = {
  [SupportedChainId.MAINNET]: [
    {
      vaultAddress: '0xBD7c6D2edE836b6b27C461799c4e9ecB8F4e8A66',
      invertPair: false,
      symbolA: 'WBTC',
      symbolB: 'USDC',
      withdrawOnly: true,
      description: 'Manages your liquidity using a passive rebalancing strategy',
      version: 'V1',
    },
    {
      vaultAddress: '0x9bf7b46c7ad5ab62034e9349ab912c0345164322',
      invertPair: true,
      symbolA: 'ETH',
      symbolB: 'USDC',
      withdrawOnly: true,
      description: 'Manages your liquidity using a passive rebalancing strategy',
      version: 'V1',
    },
    {
      vaultAddress: '0xe72f3e105e475d7db3a003ffa377afae9c2c6c11',
      invertPair: false,
      symbolA: 'ETH',
      symbolB: 'USDT',
      withdrawOnly: true,
      description: 'Manages your liquidity using a passive rebalancing strategy',
      version: 'V1',
    },
    {
      vaultAddress: '0x55535c4c56f6bf373e06c43e44c0356aafd0d21a',
      invertPair: false,
      symbolA: 'ETH',
      symbolB: 'USDT',
      withdrawOnly: true,
      description: 'Manages your liquidity using a passive rebalancing strategy',
      version: 'V0',
    },
  ],
  [SupportedChainId.RINKEBY]: [
    {
      vaultAddress: '0x49d13C92C52312ff843E90ff4943A80dA3DE978e',
      invertPair: true,
      symbolA: 'ETH',
      symbolB: 'USDC',
      withdrawOnly: true,
      description: 'Manages your liquidity using a passive rebalancing strategy',
      version: 'V0',
    },
  ],
}
