import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { useWallet } from 'use-wallet'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

function Header() {
  const { account, connect, reset, status } = useWallet()

  const address = account || ''
  const prefix = address.substring(0, 6)

  const navigation: { name: string; href: string; current?: boolean }[] = [
    {
      name: 'Docs',
      href: 'https://learn.charm.fi/charm-finance/alpha-vaults/',
    },
    { name: 'Discord', href: 'https://discord.gg/6BY3Fq2' },
  ]

  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-6xl flex flex-row items-center mt-10 px-10">
            <div className="flex-grow">
              <a className="focus:outline-none" href="https://charm.fi" target="_blank" rel="noopener noreferrer">
                <img className="hidden md:block h-8 w-auto" src="/logoWithText.png" alt="Charm Finance" />
                <img className="block md:hidden h-8 w-auto" src="/logo.png" alt="Charm Finance" />
              </a>
            </div>

            <div className="hidden sm:block flex-grow flex flex-row items-center space-x-12">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-md text-gray-600 hover:text-gray-800"
                >
                  {item.name}
                </a>
              ))}
            </div>

            <div className="flex flex-row">
              {status === 'disconnected' ? (
                <button
                  type="button"
                  className="inline-flex items-center px-5 py-3 text-xs rounded-full font-medium text-gray-500 border border-gray-200 tracking-wide focus:outline-none"
                  onClick={() => connect('injected')}
                >
                  Connect wallet
                </button>
              ) : status === 'connecting' ? (
                <button
                  type="button"
                  className="inline-flex items-center px-5 py-3 text-xs rounded-full font-medium text-gray-500 border border-gray-200 tracking-wide focus:outline-none"
                >
                  Connecting
                </button>
              ) : status === 'error' ? (
                <button
                  type="button"
                  className="inline-flex items-center px-5 py-3 text-xs rounded-full font-medium text-gray-500 border border-gray-200 tracking-wide focus:outline-none"
                >
                  Wrong network
                </button>
              ) : (
                <button
                  type="button"
                  className="inline-flex items-center px-5 py-3 text-xs rounded-full font-medium text-gray-500 border border-gray-200 tracking-wide focus:outline-none"
                  onClick={() => reset()}
                >
                  {prefix}...
                </button>
              )}
            </div>

            <div className="flex items-center sm:hidden">
              {/* Mobile menu button*/}
              <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 text-right">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames('block px-10 py-2 text-md text-gray-600 hover:text-gray-800')}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Header
