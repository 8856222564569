import { ToastContainer as Container } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'

const StyledContainer = styled(Container).attrs({})`
  .Toastify__progress-bar {
    background: #2563eb;
  }
`

function ToastContainer() {
  return (
    <StyledContainer
      position="top-right"
      autoClose={20000}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      toastClassName="cursor-default"
      bodyClassName="p-5 bg-white text-gray-700 tracking-wide"
    />
  )
}

export default ToastContainer
