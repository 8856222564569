import { Decimal } from 'decimal.js'

export const UINT256_MAX = Decimal.pow(2, 256).sub(1)
export const TICK_SIZE = new Decimal('1.0001')

export const tickToPrice = (x: Decimal.Value) => {
  return Decimal.pow(TICK_SIZE, x)
}

export const toDecimal = (x: Decimal.Value, dp: Decimal.Value) => Decimal.div(x, Decimal.pow(10, dp))

export const toString = (x: Decimal.Value, dp: Decimal.Value) => Decimal.mul(x, Decimal.pow(10, dp)).toFixed(0)

export const display = (x: Decimal | null | undefined, sf: number) => {
  if (x === null || x === undefined) {
    return ''
  }
  let s
  if (x.gte(Decimal.pow(10, sf))) {
    s = x.toFixed(0, Decimal.ROUND_DOWN)
  } else {
    s = x.toSignificantDigits(sf, Decimal.ROUND_DOWN).toFixed()
  }
  return s

  // Add thousands separator
  // const parts = s.toString().split(".");
  // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // return parts.join(".");
}
