const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

export function escapeRegExp(s: string) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}
type NumericalInputProps = {
  value: string
  onChangeValue: (s: string) => any
} & React.HTMLAttributes<HTMLInputElement>

function NumericalInput(props: NumericalInputProps) {
  const { onChangeValue } = props
  const rest: any = { ...props }
  delete rest.onChangeValue

  const enforcer = (s: string) => {
    if (s === '' || inputRegex.test(escapeRegExp(s))) {
      if (s === '.') {
        s = '0.'
      }
      onChangeValue(s)
    }
  }
  return (
    <input
      required
      {...rest}
      onChange={(e) => enforcer(e.target.value)}
      type="text"
      inputMode="decimal"
      placeholder="0.0"
      pattern="^[0-9]*[.,]?[0-9]*$"
      autoCorrect="off"
      autoComplete="off"
      spellCheck="false"
      minLength={1}
      maxLength={79}
    />
  )
}

export default NumericalInput
