import { useCallback, useEffect, useState } from 'react'
import { Vault } from 'types/vault'
import { useWallet } from 'use-wallet'

import fetchVault from '../utils/fetchVault'

import useBlock from './useBlock'

export const useVault = (address?: string): Vault | null => {
  const [result, setResult] = useState<Vault | null>(null)
  const { account } = useWallet()
  const block = useBlock()

  const fetch = useCallback(async () => {
    if (!address) {
      return
    }
    const result = await fetchVault(address, account)
    setResult(result)
  }, [account, address])

  useEffect(() => {
    if (!address) {
      return
    }
    fetch()
  }, [fetch, address, block])

  return result
}

export default useVault
