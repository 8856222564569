import { useState } from 'react'
import { Decimal } from 'decimal.js'
import { WETH9 } from '@uniswap/sdk-core'
import { useWallet } from 'use-wallet'
import Web3 from 'web3'

import { CHAIN_ID } from '../../constants'
import { toDecimal } from '../../utils/math'
import { sendWrapEth } from '../../utils/methods'

import { GraySmallButton, IndigoSmallButton } from '../Buttons'
import Loading from '../Loading'
import NumericalInput from '../NumericalInput'

function WrapEthPanel() {
  const { account, balance, ethereum } = useWallet()
  const [value, setValue] = useState('')
  const [isPending, setIsPending] = useState(false)

  const amount = new Decimal(value || 0)

  const emptyAmount = amount.eq(0)
  const insufficientBalance = amount.gt(toDecimal(balance, 18))

  const onChangeValue = (value: string) => {
    setValue(value)
  }

  const onWrap = () => {
    if (!account) {
      return
    }
    // @ts-ignore
    const e = ethereum
    const web3 = new Web3(e as any)
    const weth9Address = WETH9[CHAIN_ID].address
    sendWrapEth({ web3, account, weth9Address, value, setIsPending })
  }

  return (
    <div className="bg-white rounded-2xl shadow-sm px-6 md:px-10 pt-6 pb-10">
      <div className="text-center text-gray-900 text-sm font-semibold">ETH → WETH</div>

      <div className="flex flex-row items-center mt-8">
        <div className="flex-grow pr-4">
          <NumericalInput
            value={value}
            onChangeValue={onChangeValue}
            className="h-12 w-full px-4 text-gray-700 font-medium text-xl text-right tracking-wider border border-pink-100 bg-pink-50 rounded-md"
          />
        </div>

        <div className="flex-none">
          {isPending ? (
            <GraySmallButton>
              Pending <Loading />
            </GraySmallButton>
          ) : emptyAmount ? (
            <GraySmallButton>Wrap ETH</GraySmallButton>
          ) : insufficientBalance ? (
            <GraySmallButton>Not enough ETH</GraySmallButton>
          ) : (
            <IndigoSmallButton onClick={onWrap}>Wrap ETH</IndigoSmallButton>
          )}
        </div>
      </div>
    </div>
  )
}

export default WrapEthPanel
