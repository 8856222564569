import { Decimal } from 'decimal.js'
import { CHAIN_ID, ETHERSCAN_LINK } from '../../constants'
import { display } from 'utils/math'
import PopoutIcon from '../PopoutIcon'
import { Vault, VaultInfo } from 'types/vault'

type InfoPanelProps = Vault & VaultInfo

function InfoPanel({
  baseLower,
  baseUpper,
  price,
  poolAddress,
  limitLower,
  limitUpper,
  symbol0,
  symbol1,
  total0,
  total1,
  totalSupply,
  maxTotalSupply,
  strategyAddress,
  vaultAddress,
  invertPair,
  withdrawOnly,
}: InfoPanelProps) {
  const pair = invertPair ? symbol1 + ' / ' + symbol0 : symbol0 + ' / ' + symbol1

  const _price = invertPair ? new Decimal(1).div(price) : price
  const _baseLower = invertPair ? new Decimal(1).div(baseUpper) : new Decimal(baseLower)
  const _baseUpper = invertPair ? new Decimal(1).div(baseLower) : new Decimal(baseUpper)
  const _limitLower = invertPair ? new Decimal(1).div(limitUpper) : new Decimal(limitLower)
  const _limitUpper = invertPair ? new Decimal(1).div(limitLower) : new Decimal(limitUpper)

  const tvl1 = total0.mul(price).add(total1)
  const tvl = invertPair ? tvl1.div(price) : tvl1
  const maxTvl = tvl.mul(maxTotalSupply).div(totalSupply)
  const ratio0 = total0.mul(price).mul(100).div(tvl1)
  const ratio1 = total1.mul(100).div(tvl1)

  const isOpen = maxTotalSupply.gt(0)
  const utilization = totalSupply.mul(100).div(maxTotalSupply)
  // const capAmount0 = total0.mul(maxTotalSupply).div(totalSupply);
  // const capAmount1 = total1.mul(maxTotalSupply).div(totalSupply);

  return (
    <div className="flex-grow p-6 md:p-12 lg:p-24 pt-16 bg-white rounded-2xl shadow-sm">
      <h1 className="text-xl text-gray-900 font-semibold">{pair} Alpha Vault</h1>

      <div className="mt-1 text-gray-600 text-md leading-relaxed tracking-wide">
        {withdrawOnly && (
          <p className="inline-block mt-6 mb-2 font-normal text-sm bg-yellow-50 px-8 py-6 text-gray-500 tracking-wide leading-relaxed rounded-sm">
            This is an old vault that has been discontinued and no longer generates yield. Please withdraw your funds
            from it.
            <br />
            <br />
            The new vaults are found at{' '}
            <a
              href="https://alpha.charm.fi/vaults"
              className="font-medium text-blue-500 dark:text-blue-500 hover:underline"
            >
              alpha.charm.fi
            </a>
          </p>
        )}
        <p className="mt-5">This vault automatically manages liquidity on Uniswap V3 for you.</p>
        <p className="mt-5">
          It concentrates its liquidity to earn higher yields and automatically adjusts its range orders as the
          underlying price moves so that it can continue to capture fees.
        </p>
        <p className="mt-5">
          By using a passive rebalancing strategy to rebalance its inventory, it avoids paying swap fees on Uniswap or
          suffering from price impact.
        </p>
        <p className="mt-5">
          5% of fees earned from Uniswap go to the Charm treasury, to be controlled by Charm governance.
        </p>
      </div>

      <h2 className="mt-16 text-gray-900 text-lg font-medium">Vault holdings</h2>
      <div className="mt-4 text-gray-500 text-md leading-relaxed tracking-wider grid grid-cols-3 gap-3">
        <div>TVL</div>
        <div className="col-span-2 text-indigo-600 font-medium">${display(tvl, 4)}</div>

        <div>Total {symbol0}</div>
        <div className="col-span-2 text-indigo-600 font-medium">{display(total0, 4)}</div>

        <div>Total {symbol1}</div>
        <div className="col-span-2 text-indigo-600 font-medium">{display(total1, 4)}</div>

        <div>
          {symbol0} & {symbol1} ratio
        </div>
        <div className="col-span-2 text-indigo-600 font-medium">
          {display(ratio0, 2)} : {display(ratio1, 2)}
        </div>
      </div>

      <h2 className="mt-16 text-gray-900 text-lg font-medium">Deposit cap</h2>
      <div className="mt-4 text-gray-500 text-md leading-relaxed tracking-wider grid grid-cols-3 gap-3">
        <div>% of cap used</div>
        <div className="col-span-2 text-indigo-600 font-medium">{isOpen ? display(utilization, 3) + '%' : '-'}</div>

        <div>Max TVL</div>
        <div className="col-span-2 text-indigo-600 font-medium">{isOpen ? '$' + display(maxTvl, 4) : '-'}</div>
      </div>

      <h2 className="mt-16 text-gray-900 text-lg font-medium">Vault positions</h2>
      <div className="mt-4 text-gray-500 text-md leading-relaxed tracking-wider grid grid-cols-3 gap-3">
        <div>{pair} price</div>
        <div className="col-span-2 text-indigo-600 font-medium">{display(_price, 4)}</div>

        <div>Base order</div>
        <div className="col-span-2 text-indigo-600 font-medium">
          {display(_baseLower, 4)} - {display(_baseUpper, 4)}
        </div>

        <div>Limit order</div>
        <div className="col-span-2 text-indigo-600 font-medium">
          {display(_limitLower, 4)} - {display(_limitUpper, 4)}
        </div>
      </div>

      <h2 className="mt-16 text-gray-900 text-lg font-medium">Contracts</h2>
      <div className="mt-4 text-gray-500 text-md leading-relaxed tracking-wider grid grid-cols-3 gap-3">
        <div>Vault</div>
        <div className="col-span-2 text-indigo-600 font-medium">
          {vaultAddress.substring(0, 8)}... <PopoutIcon href={ETHERSCAN_LINK[CHAIN_ID] + 'address/' + vaultAddress} />
        </div>

        <div>Strategy</div>
        <div className="col-span-2 text-indigo-600 font-medium">
          {strategyAddress.substring(0, 8)}...{' '}
          <PopoutIcon href={ETHERSCAN_LINK[CHAIN_ID] + 'address/' + strategyAddress} />
        </div>

        <div>Uniswap V3 Pool</div>
        <div className="col-span-2 text-indigo-600 font-medium">
          {pair} <PopoutIcon href={ETHERSCAN_LINK[CHAIN_ID] + 'address/' + poolAddress} />
        </div>
      </div>
    </div>
  )
}

export default InfoPanel
