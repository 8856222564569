import { Decimal } from 'decimal.js'
import { useState } from 'react'
import { useWallet } from 'use-wallet'
import Web3 from 'web3'
import { display } from 'utils/math'
import { sendWithdraw } from 'utils/methods'
import { GrayButton, IndigoButton } from '../Buttons'
import Loading from '../Loading'
import NumericalInput from '../NumericalInput'

interface WithdrawPanelProps {
  setIsDeposit: (b: boolean) => void
  vaultAddress: string
  balance: Decimal
  symbol0: string
  symbol1: string
  decimals0: Decimal.Value
  decimals1: Decimal.Value
  total0: Decimal.Value
  total1: Decimal.Value
  totalSupply: Decimal.Value
}
function WithdrawPanel({
  setIsDeposit,
  vaultAddress,
  balance,
  symbol0,
  symbol1,
  decimals0,
  decimals1,
  total0,
  total1,
  totalSupply,
}: WithdrawPanelProps) {
  const { account, ethereum } = useWallet()
  const [value, setValue] = useState('')
  const [isPending, setIsPending] = useState(false)

  const shares = new Decimal(value || 0)
  const amount0 = shares.mul(total0).div(totalSupply)
  const amount1 = shares.mul(total1).div(totalSupply)

  const onChangeValue = (value: string) => {
    setValue(value)
  }

  const onSetMax = () => {
    onChangeValue(balance.toString())
  }

  const onWithdraw = () => {
    if (!account) {
      return
    }
    // @ts-ignore
    const e = ethereum
    const web3 = new Web3(e as any)

    // set min amount outs to 90%
    const amount0Min = Decimal.max(0, amount0.sub(1)).mul(9).div(10)
    const amount1Min = Decimal.max(0, amount1.sub(1)).mul(9).div(10)
    sendWithdraw({
      web3,
      account,
      vaultAddress,
      value,
      amount0Min,
      amount1Min,
      decimals0,
      decimals1,
      setIsPending,
    })
  }

  const emptyAmount = shares.eq(0)
  const insufficientBalance = shares.gt(balance)

  return (
    <div className="bg-white rounded-2xl shadow-sm">
      <div className="grid grid-cols-2 text-center">
        <button
          className="p-4 text-gray-500 text-sm font-semibold bg-pink-100 rounded-tl-2xl focus:outline-none"
          onClick={() => setIsDeposit(true)}
        >
          Deposit
        </button>
        <button className="p-4 text-gray-900 text-sm font-semibold cursor-auto focus:outline-none">Withdraw</button>
      </div>

      <div className="px-6 md:px-10 py-8 md:py-12">
        <div className="px-2">
          <div className="text-gray-400 text-sm text-right">
            Balance: {display(balance, 4)}
            <button className="ml-1 text-indigo-400" onClick={onSetMax}>
              (Max)
            </button>
          </div>

          <div className="flex flex-row items-center mt-1">
            <div className="w-32 text-gray-900 font-medium text-md tracking-wide">Vault shares</div>
            <NumericalInput
              value={value}
              onChangeValue={onChangeValue}
              className="w-40 p-3 text-gray-700 font-medium text-xl text-right tracking-wider border border-pink-100 bg-pink-50 rounded-md"
            />
          </div>
        </div>

        <div className="mt-20 text-center text-gray-400 text-sm">You'll receive</div>

        <div className="mt-4 px-10 flex flex-row items-center">
          <div className="flex-grow text-gray-900 font-medium text-sm tracking-wider">{symbol0}</div>
          <div className="text-green-400 font-medium text-lg text-right tracking-wider">{display(amount0, 4)}</div>
        </div>

        <div className="mt-3 px-10 flex flex-row items-center">
          <div className="flex-grow text-gray-900 font-medium text-sm tracking-wider">{symbol1}</div>
          <div className="text-green-400 font-medium text-lg text-right tracking-wider">{display(amount1, 4)}</div>
        </div>

        <div className="mt-12">
          {isPending ? (
            <GrayButton>
              Pending <Loading />
            </GrayButton>
          ) : emptyAmount ? (
            <GrayButton>Withdraw</GrayButton>
          ) : insufficientBalance ? (
            <GrayButton>Insufficient balance</GrayButton>
          ) : (
            <IndigoButton onClick={onWithdraw}>Withdraw</IndigoButton>
          )}
        </div>
      </div>
    </div>
  )
}

export default WithdrawPanel
