import { Decimal } from 'decimal.js'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CHAIN_ID, VAULTS } from '../../constants'
import useVault from '../../hooks/useVault'
import DepositPanel from './DepositPanel'
import InfoPanel from './InfoPanel'
import PositionPanel from './PositionPanel'
import WithdrawPanel from './WithdrawPanel'
import WrapEthPanel from './WrapEthPanel'

function Vault() {
  const { address } = useParams<{ address?: string }>()
  const [isDeposit, setIsDeposit] = useState(true)

  const info = VAULTS[CHAIN_ID].find((x) => x.vaultAddress === address)
  const vault = useVault(address)
  const isEth = info?.symbolA === 'ETH' || info?.symbolB === 'ETH'

  // dumb hack that only works because all vaults are against stables
  // assumes 1 stable === 1 usd
  const USDValue = useMemo(() => {
    if (!vault || !info || vault.balance.eq(0)) {
      return new Decimal(0)
    }
    const { balance, total0, total1, totalSupply } = vault
    const amount0 = balance.mul(total0).div(totalSupply)
    const amount1 = balance.mul(total1).div(totalSupply)
    if (info.invertPair) {
      const price = new Decimal(1).div(vault.price)
      return price.mul(amount1).plus(amount0)
    } else {
      const price = vault.price
      return price.mul(amount0).plus(amount1)
    }
  }, [info, vault])

  if (!info || !vault) {
    return <div className="mt-24 text-2xl text-gray-400 text-center tracking-wide">Loading...</div>
  }

  return (
    <div className="mx-4 md:mx-6 lg:mx-auto my-auto max-w-6xl flex flex-col md:flex-row gap-8">
      <InfoPanel {...vault} {...info} />
      <div>
        <div>
          <div className={isDeposit ? '' : 'hidden'}>
            <DepositPanel setIsDeposit={setIsDeposit} {...vault} />
          </div>
          <div className={isDeposit ? 'hidden' : ''}>
            <WithdrawPanel setIsDeposit={setIsDeposit} {...vault} />
          </div>
        </div>

        {isEth ? (
          <div className="mt-10">
            <WrapEthPanel />
          </div>
        ) : null}

        <div className="mt-10">
          <PositionPanel {...vault} USDValue={USDValue} />
        </div>
      </div>
    </div>
  )
}

export default Vault
